<template>
  <div class="users-home">
    <users-header />
    <users-list />
  </div>
</template>

<script>
import UsersHeader from '@/modules/users/components/UsersHeader.vue'
import UsersList from '@/modules/users/components/list/UsersList.vue'
import usersService from '@/modules/users/services/usersService'

export default {
  name: 'UsersHome',

  components: {
    UsersHeader,
    UsersList,
  },

  data() {
    return {
      loading: false,
      profiles: [],
    }
  },

  async created() {
    await this.listProfile()
  },

  provide() {
    return {
      getProfiles: this.getProfiles,
    }
  },

  methods: {
    async listProfile() {
      this.loading = true
      try {
        const { data } = await usersService.getProfiles()
        this.profiles = data
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    getProfiles() {
      return this.profiles
    },
  },
}
</script>

<style lang="scss" scoped>
.users-home {
  max-width: 1440px;
  margin: 0px auto;
}
</style>
