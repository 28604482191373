import colors from '@/assets/styles/__colors.module.scss'
import spacers from '@/assets/styles/__spacers.module.scss'

class DfSwitch extends HTMLElement {
  static get observedAttributes() {
    return ['checked', 'disabled', 'loading', 'size']
  }

  attributeChangedCallback(name, _, newValue) {
    if (name === 'checked') {
      this._checked = newValue !== null

      if (this._checked) {
        this.addClass('checked')
      } else {
        this.removeClass('checked')
      }
    }
    if (name === 'disabled') {
      this._disabled = newValue === 'true'

      if (this._disabled) {
        this.addClass('disabled')
      } else {
        this.removeClass('disabled')
      }
    }
    if (name === 'loading') {
      this._loading = newValue

      if (this._loading) {
        this.addClass('loading')
      } else {
        this.removeClass('loading')
      }
    }
    if (name === 'size') {
      this._size = newValue
    }
  }

  connectedCallback() {
    const switchComponent = this.shadowRoot.querySelector('.df-switch')
    switchComponent.addEventListener('click', () => this.toggle())

    if (this._disabled) {
      this.addClass('disabled')
    } else {
      this.removeClass('disabled')
    }

    if (this._loading) {
      this.addClass('loading')
    } else {
      this.removeClass('loading')
    }

    this.updateStyles()
  }

  disconnectedCallback() {
    const switchComponent = this.shadowRoot.querySelector('.df-switch')
    switchComponent.removeEventListener('click', () => this.toggle())
  }

  addClass(className) {
    const switchComponent = this.shadowRoot.querySelector('.df-switch')

    if (!Array.from(switchComponent.classList).includes(className))
      switchComponent.classList.add(className)
  }

  removeClass(className) {
    const switchComponent = this.shadowRoot.querySelector('.df-switch')

    if (Array.from(switchComponent.classList).includes(className))
      switchComponent.classList.remove(className)
  }

  toggle() {
    if (!this._disabled && !this._loading) {
      this.dispatchEvent(
        new CustomEvent('change', {
          detail: { checked: this._checked },
        })
      )
    }
  }

  updateStyles() {
    const switchHeightSizeObj = {
      small: '20px',
      medium: '24px',
      large: '32px',
    }
    const switchWidthSizeObj = {
      small: '40px',
      medium: '48px',
      large: '64px',
    }
    const switchBorderRadiusSizeObj = {
      small: '20px',
      medium: '24px',
      large: '32px',
    }
    const switchBallSizeObj = {
      small: '14px',
      medium: '18px',
      large: '26px',
    }

    const switchComponent = this.shadowRoot.querySelector('.df-switch')
    switchComponent.style.setProperty(
      '--switch-height',
      switchHeightSizeObj[this._size]
    )
    switchComponent.style.setProperty(
      '--switch-width',
      switchWidthSizeObj[this._size]
    )
    switchComponent.style.setProperty(
      '--switch-border-radius',
      switchBorderRadiusSizeObj[this._size]
    )
    switchComponent.style.setProperty(
      '--switch-ball',
      switchBallSizeObj[this._size]
    )
  }

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })
    const { colorBackgroundDisabled } = colors
    const { colorBackgroundNeutral } = colors
    const { colorBackgroundWhite } = colors
    const { colorBackgroundPrimaryLight } = colors
    const { spacingXs } = spacers

    this._checked = this.hasAttribute('checked')
    this._disabled = false
    this._loading = false
    this._size = 'medium'

    this.shadowRoot.innerHTML = `
      <style>
        .df-switch {
          display: inline-block;
          position: relative;
          height: var(--switch-height);
          width: var(--switch-width);
        }
        .df-switch .slider {
          position: absolute;
          cursor: pointer;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          background-color: ${colorBackgroundNeutral};
          transition: 0.5s;
          border-radius: var(--switch-border-radius);
          overflow: hidden;
        }
        .df-switch .slider:before {
          position: absolute;
          content: "";
          top: 50%;
          transform: translateY(-50%);
          height: var(--switch-ball);
          width: var(--switch-ball);
          background-color: ${colorBackgroundWhite};
          transition: 0.5s;
          border-radius: 50%;
        }
        .df-switch.checked .slider:before {
          left: calc(100% - var(--switch-ball) - ${spacingXs});
        }
        .df-switch:not(.checked) .slider:before {
          left: ${spacingXs};
        }
        .df-switch.checked .slider {
          background-color: ${colorBackgroundPrimaryLight};
        }
        .df-switch.disabled .slider {
          cursor: auto;
          background-color: ${colorBackgroundDisabled};
        }
        .df-switch.disabled .slider:before {
          background-color: #aab2a9;
        }
        .df-switch.loading .slider {
          cursor: auto;
          background-color: ${colorBackgroundDisabled};
        }
        .df-switch.loading .slider:before {
          display: none;
        }
        .df-switch.loading .slider::after {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          height: 100%;
          width: 200%;
          background: linear-gradient(
            105deg,
            #aab2a9 0%,
            #aab2a9 45%,
              transparent 55%,
              transparent 100%
          );
          animation: progress 1.5s linear infinite;
        }
        @keyframes progress {
          0% {
            transform: translateX(0%);
          }
          100% {
            transform: translateX(50%);
          }
        }
      </style>
      <div class="df-switch">
        <div class="slider"></div>
      </div>
    `
  }
}

customElements.define('df-switch', DfSwitch)
