<template>
  <div class="users-header">
    <df-page-header
      :subtitle="$t('Users.home.configurations')"
      :title="$t('Users.home.users')"
    >
      <template #actions>
        <df-button icon="plus" @click="openFormRegisterUser">
          {{ $t('Users.home.register') }}
        </df-button>
      </template>
    </df-page-header>
    <add-user-modal v-if="isAddUserModalOpened" @close="closeAddUserModal" />
  </div>
</template>

<script>
const AddUserModal = () =>
  import('@/modules/users/components/modal/AddUserModal.vue')
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfPageHeader from '@/lib/components/Header/DfPageHeader.vue'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'UsersHeader',

  components: {
    AddUserModal,
    DfButton,
    DfPageHeader,
  },

  data() {
    return {
      isAddUserModalOpened: false,
    }
  },

  methods: {
    closeAddUserModal() {
      this.isAddUserModalOpened = false
    },
    openFormRegisterUser() {
      this.isAddUserModalOpened = true
      logEvent(events.usersModule.register)
    },
  },
}
</script>
