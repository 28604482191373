<template>
  <div class="users-list">
    <div class="df-flex-l df-flex-m-t list__header">
      <div class="df-col-4">
        <span>{{ $t('Users.list.user') }}</span>
      </div>
      <div class="df-col-4">
        <span>{{ $t('Users.email') }}</span>
      </div>
      <div class="df-col-2">
        <span>{{ $t('Users.list.status') }}</span>
      </div>
      <div class="df-col-2"></div>
    </div>

    <div class="list__content">
      <div v-show="shouldShowLoading" class="df-flex-l df-flex-col">
        <div
          v-for="(_, index) in users.length || 3"
          :key="index"
          class="df-flex-l df-flex-col"
        >
          <df-skeleton-loader height="56px" />
          <v-divider v-if="shouldShowDivider(index)" />
        </div>
      </div>
      <div v-show="shouldShowContent" class="df-flex-l df-flex-col">
        <div
          v-for="(user, index) in usersWithProfileUids"
          :key="user.id"
          class="df-flex-l df-flex-col"
        >
          <users-list-item :user="user" />
          <v-divider v-if="shouldShowDivider(index)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsersListItem from '@/modules/users/components/list/UsersListItem.vue'
import { mapActions, mapGetters } from 'vuex'
import '@/lib/components/Loader/DfSkeletonLoader.js'

export default {
  name: 'UsersList',

  components: {
    UsersListItem,
  },

  async created() {
    await this.fetchUsers()
  },

  inject: {
    getProfiles: {
      from: 'getProfiles',
    },
  },

  computed: {
    ...mapGetters('user', ['loadingUsers', 'users']),
    shouldShowContent() {
      return !this.loadingUsers
    },
    shouldShowLoading() {
      return this.loadingUsers
    },
    usersWithProfileUids() {
      return this.users?.map((user) => ({
        ...user,
        profileUid: this.getProfiles()?.find(
          (profile) => profile.id === user.profile_id
        )?.uid,
      }))
    },
  },

  methods: {
    ...mapActions('user', ['fetchUsers']),
    shouldShowDivider(index) {
      return index !== this.usersWithProfileUids?.length - 1
    },
  },
}
</script>

<style lang="scss" scoped>
.users-list {
  margin: $spacing-l;
  background-color: $color-background-white;
  border: 1px solid $color-border;
  border-radius: $spacing-sm;

  @include d(m) {
    margin: 0px $spacing-sm $spacing-sm $spacing-sm;
  }

  .list__header {
    padding: $spacing-l;
    border-bottom: 1px solid $color-border;

    @include d(m) {
      display: none;
    }
    span {
      @include label-x-small;
      color: $color-text-neutral;
    }
  }
  .list__content {
    padding: $spacing-l;

    @include d(m) {
      padding: 0px;
    }
  }
}
</style>
