<template>
  <div class="users-list-item">
    <div class="df-flex-l df-flex-m-t align-center">
      <div class="df-col-4">
        <div class="df-flex-sm df-flex-col justify-start item__name">
          <p>{{ shownName }}</p>
          <span>{{ profileName }}</span>
        </div>
      </div>
      <div class="df-col-4 item__email">
        <div class="df-flex-sm align-center">
          <font-awesome-icon
            icon="envelope"
            size="lg"
            :color="colorIconNeutral"
          />
          <span>
            {{ user.email }}
          </span>
        </div>
      </div>
      <div class="df-col-2 item__activeness">
        <div class="df-flex-m align-center">
          <df-switch
            :checked="isUserActive"
            :disabled="isSameUser(user)"
            :loading="loading"
            @change="toggleUserActiveness(user.id)"
          />
          <span>
            {{
              user.active
                ? $t('Users.list_item.active')
                : $t('Users.list_item.inactive')
            }}
          </span>
        </div>
      </div>
      <div class="df-col-2">
        <div class="df-flex-m justify-end">
          <df-button
            min-width="36px"
            variant="secondary"
            width="36px"
            @click="editUser"
          >
            <font-awesome-icon icon="edit" :color="colorIconNeutral" />
          </df-button>
          <df-button
            min-width="36px"
            variant="secondary"
            width="36px"
            :disabled="isSameUser(user)"
            @click="openDeleteUserModal"
          >
            <font-awesome-icon icon="trash" :color="colorIconNeutral" />
          </df-button>
        </div>
      </div>
    </div>
    <add-user-modal
      v-if="isAddUserModalOpened"
      :user="user"
      @close="closeAddUserModal"
    />
    <exceeded-users-modal
      v-if="isExceededUsersModalOpened"
      @close="closeExceededUsersModal"
    />
    <action-dialog
      color-cancel="#8f8f8f"
      color-confirm="#39af49"
      :dialog="isDeleteUserModalOpened"
      :cancel="closeDeleteUserModal"
      :confirm="confirmDelete"
      :text="$t('Users.list_item.delete_confirm')"
      :text-cancel="$t('Users.list_item.cancel')"
      :text-confirm="$t('Users.list_item.confirm')"
    />
  </div>
</template>

<script>
import ActionDialog from '@/components/Dialogs/ActionConfirmationDialog.vue'
import colors from '@/assets/styles/__colors.module.scss'
import DfButton from '@/lib/components/Button/DfButton.vue'
import { mapActions, mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'
import '@/lib/components/Switch/DfSwitch.js'
const AddUserModal = () =>
  import('@/modules/users/components/modal/AddUserModal.vue')
const ExceededUsersModal = () =>
  import('@/modules/users/components/modal/ExceededUsersModal.vue')

export default {
  name: 'UsersListItem',

  components: {
    ActionDialog,
    AddUserModal,
    DfButton,
    ExceededUsersModal,
  },

  props: {
    user: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      colorIconNeutral: colors.colorIconNeutral,
      isAddUserModalOpened: false,
      isDeleteUserModalOpened: false,
      isExceededUsersModalOpened: false,
      loading: false,
    }
  },

  computed: {
    ...mapGetters('user', ['currentUser']),
    isUserActive: {
      get() {
        return this.user.active
      },
      set() {},
    },
    profileName() {
      let translatedName = ''
      if (this.user.profileUid) {
        translatedName = this.$t('Users.profiles.' + this.user.profileUid)
      }
      return translatedName
    },
    shownName() {
      return this.user.name || this.user.email
    },
  },

  methods: {
    ...mapActions('user', ['changeUserStatus', 'deleteUser']),
    closeAddUserModal() {
      this.isAddUserModalOpened = false
    },
    closeDeleteUserModal() {
      this.isDeleteUserModalOpened = false
    },
    closeExceededUsersModal() {
      this.isExceededUsersModalOpened = false
    },
    async confirmDelete() {
      try {
        await this.deleteUser(this.user.id)
        logEvent(events.usersModule.delete)
        this.$root.$emit(
          'notify',
          'success',
          this.$t('Users.list_item.delete_success')
        )
      } catch (error) {
        console.error(error)
      } finally {
        this.isDeleteUserModalOpened = false
      }
    },
    editUser() {
      this.isAddUserModalOpened = true
      logEvent(events.usersModule.edit)
    },
    isSameUser(user) {
      return this.currentUser.id === user.id
    },
    openDeleteUserModal() {
      this.isDeleteUserModalOpened = true
    },
    async toggleUserActiveness(userId) {
      this.loading = true
      try {
        await this.changeUserStatus(userId)
        logEvent(events.usersModule.status)
      } catch (error) {
        if (error.response?.data?.key === 'exception.users.exceeded_limit') {
          this.isExceededUsersModalOpened = true
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.users-list-item {
  padding: $spacing-sm 0px;
  align-items: center;

  @include d(m) {
    padding: $spacing-l;
    align-items: stretch;
  }
  .item__name {
    p {
      @include label-small;
      color: $color-text-secondary;
    }
    span {
      @include label-x-small;
      color: $color-text-neutral;
    }
  }
  .item__email {
    span {
      @include label-small;
      color: $color-text-neutral;
      word-wrap: break-word;
      white-space: normal;
      max-width: 100%;
    }
  }
  .item__activeness {
    @include label-x-small;
    color: $color-text-neutral;
    text-transform: uppercase;
  }
}
::v-deep .v-input--selection-controls {
  margin: 0px !important;
  padding: 0px !important;
}
</style>
